import http from '@/utils/http'
class Api {
  /* 订单确认。获取订单信息 */
  SUREBIDEERINFO = (data: any) => {
    return http.post('cashier_v3/pay/sure-bider-info', data)
  }

  /* 创建订单 */
  SOURCE = (data: any) => {
    return http.post('cashier_v3/pay/source', data)
  }

  /* 支付结果 */
  PAYEND = (data: any) => {
    return http.post('cashier_v3/pay/pay-end', data)
  }

  /*
  * 获取支付方式
  */
  PayType = (data: any) => {
    return http.post('/cashier_v3/pay/pay-info', data)
  }

  PayParams = (data: any) => {
    return http.post('/cashier_v3/pay/get-params', data)
  }

  /*
  * 获取支付链接
  */
  PayLink = (data: any) => {
    return http.post('/cashier_v3/pay/pay-link', data)
  }

  /*
  * 获取支付状态
  */
  PayEnd = (data: any) => {
    return http.post('/cashier_v3/pay/pay-end', data)
  }
}
export default new Api()
